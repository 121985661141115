<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
<!--        <div class='table-search'>-->

<!--          <el-form :model='searchCondition' inline size='mini'>-->
<!--            <el-form-item label='创建时间'>-->
<!--              <el-date-picker-->
<!--                v-model='searchCondition.date_range'-->
<!--                type='daterange'-->
<!--                align='right'-->
<!--                unlink-panels-->
<!--                range-separator='至'-->
<!--                start-placeholder='开始日期'-->
<!--                end-placeholder='结束日期'-->
<!--                value-format='yyyy-MM-dd'-->
<!--                :picker-options='pickerOptions'>-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--              <el-button type='primary' icon='el-icon-search' @click='getList'>搜索</el-button>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </div>-->
        <div class='default-table'>
          <!--    渲染表格-->
          <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' @selection-change='handleSelectionChange' border>
<!--            <el-table-column type='selection' width='55' align='center' slot='first-column' />-->
<!--            <el-table-column label='操作' width='100' align='center' fixed='right'>-->
<!--              <template slot-scope='{row}'>-->
<!--                <el-button size='mini' type='text' icon='el-icon-view' @click='handleView(row)'>详情</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </m-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>

    </page-header-layout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
export default {
  name: 'MigrationList',
  components: { PageHeaderLayout },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      loadingStatus: false,
      searchCondition: { deal_status: null, event_type: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: 'ID',
          value: 'id',
          width: 80
        },
        {
          title: '迁移脚本(设计表和调整表结构时间+功能模块)',
          value: 'migration',
          width: 480,
          header_align:'center',
          align:'left',
        },
        {
          title: '节点',
          value: 'batch',
          width: 120
        }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      eventTypeArr: ['check_url', 'user_modify_org'],
      dealStatusMap: { 1: '待处理', 2: '处理中', 3: '成功', 4: '失败' },
      currRow: {},
      dialogVisible: false,
      options: []
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.migration) {
        condition.migration = this.searchCondition.migration
      }

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData)
      let { list, pages } = await this.$api.getMigrationList(searchCondition)
      // list.map((item) => {
      //   item['deal_status_alias'] = this.dealStatusMap[item.deal_status]
      // })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })


    },
    async loadOptionMap() {
      let { event_types, deal_status } = await this.$api.getDingTalkEventOptionMap()
      this.eventTypeArr = [...event_types]
      this.dealStatusMap = { ...deal_status }
    },

    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const operIds = row.id || this.ids
      this.$confirm('是否确认删除日志编号为"' + operIds + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.deleteRows(operIds)
        // this.msgSuccess('删除成功')
        await this.getList()
      }).catch(function() {
      })
    },
    deleteRows(operIds) {
      let del_count = this.$api.deleteSystemOperLog({ 'ids': operIds })
      if (del_count) {
        this.$notify.success('删除成功')
      }
    },
    async clearLogs() {
      let { result } = await this.$api.clearSystemOperLog()

      this.$notify.success(`操作成功，符合条件的数据共计：${result.total || 0},本次清除：${result.clear_total || 0}`)
    },
    /** 清空按钮操作 */
    handleClean() {
      this.$confirm('是否确认清空所有操作日志数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.clearLogs()
        // this.msgSuccess('清空成功')
        await this.getList()
      }).catch(function() {
      })
    },
    async getOptions() {
      let { options } = await this.$api.getSystemOperLogRouteOptions()
      console.log(options)
      this.options = options
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>